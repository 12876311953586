




































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Geozone from '@/models/graphql/Geozone';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import Booth from '@/models/graphql/Booth';
import EntityType from '@/utils/enums/EntityType';

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonViewOnMap extends VueBaseActionButtonWidget {
  @Prop({ required: true })
  protected readonly actionResult!: Geozone[] | Booth[];

  @Prop({
    required: false,
    default: null,
  })
  private readonly schemaCode!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly entityTypeName!: string;

  private get text(): string {
    return `${this.$t('actions.view-on-map')}`;
  }

  private get isDisplayed(): boolean {
    return (this.actionType === this.ActionButtonType.MAP
        && this.actionResult.length > 0
        && this.actionResult[0].exhibitHall !== null
        && this.route !== null);
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private openMap(): void {
    let geozones: string[] = [];
    this.logStats(StatLoggerCategories.VIEW_ON_MAP, StatLoggerActions.ADD, '');
    if (this.actionResult.length > 0 && this.actionResult[0].exhibitHall?.id) {
      if (this.entityTypeName === EntityType.LARGE_PRODUCT) {
        const booths = this.actionResult as unknown as Booth[];
        geozones = booths.map((b) => `${b.geozone?.id}`) as string[];
      } else {
        geozones = (this.actionResult as unknown as Geozone[]).map((b) => `${b.id}`) as string[];
      }
    }
    const routeMap = `${this.route.toString()
      .endsWith('/')
      ? this.route.toString()
        .trim()
        .slice(0, this.route.toString().length - 1)
      : this.route.toString()
      // eslint-disable-next-line max-len
        .trim()}?edition=${this.schemaCode}&hall=${this.actionResult[0].exhibitHall?.id}&${geozones.map((g, index) => `filters[geozones][${index}]=${g}`)
      .join('&')}`;
    this.$router.push(routeMap);
  }
}
